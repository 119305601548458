import Link from 'next/link';

import { IconOnlyButton } from '@lib/theme/components/IconOnlyButton';

type PropsType = {
  text: string;
  url: string;
};

export const SeeAllTile = ({ text, url }: PropsType) => (
  <div className="h-full rounded-xl bg-gray-100">
    <Link
      href={url}
      className="flex h-full flex-col items-center justify-center"
    >
      <IconOnlyButton
        round="full"
        color="tertiary/neutral/carousel"
        icon="ArrowRightIcon"
        size="sm"
        title={text}
      />
      <div className="mt-4 w-18 text-center font-medium text-gray-900">
        <p>{text}</p>
      </div>
    </Link>
  </div>
);
