import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@/config/routing';
import { CategoryProductsSectionCarousel } from '@/core/ui/components/CategoryProductSectionCarousel/CategoryProductSectionCarousel';
import { cn } from '@/core/ui/utils';

import { HomepagePromotionProductsSection } from '../../queries/types';

type PromotionProductsSectionProps = {
  section: HomepagePromotionProductsSection;
};
export const PromotionProductsSection = ({
  section,
}: PromotionProductsSectionProps) => {
  const { t } = useTranslation('home');
  const promotionUrl = APP_ROUTES.promotions(section.promotion.key);

  return (
    <div className={cn('px-4', 'xl:px-0')}>
      <CategoryProductsSectionCarousel
        products={section.products}
        title={section.title}
        categoryUrl={promotionUrl}
        hasMore={section.hasMore}
        seeAllTitle={t('See all')}
      />
    </div>
  );
};
